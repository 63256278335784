<template>
  <div class="shipments_return">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <navigator :items="navigate_items"></navigator>
        </div>
        <div class="col-12">
          <div class="card card-shadow">
            <div class="card-header form-title">{{ titlePage }}</div>
            <div class="card-body">
              <div class="container form-container">
                <div class="row">
                  <div class="col-12">
                    <label>
                      Arquivo
                    </label>
                    <b-form-file
                      ref="newfileinput"
                      placeholder="Escolha um arquivo"
                      drop-placeholder="Arraste o arquivo aqui..."
                      browse-text="Procurar"
                      v-on:change="uploadFile"
                    ></b-form-file>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <button
                      class="btn btn-primary float-right btn-submit"
                      v-on:click="save()"
                      v-bind:key="refreshCondition"
                    >
                      Salvar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import { bus } from "@/main";
import helper from "@/imports/Helpers";
import validator from "@/imports/validator/validate";
import return_validate from "@/imports/validator/models/return";

export default {
  name: "shipments_return",
  props: {
    id: Number
  },
  components: {},
  data() {
    return {
      shipment_return: {
        file: []
      },
      formType: "create",
      newFile: {},
      titlePage: "Novo Retorno",
      refreshCondition: false,
      navigate_items: [
        {
          text: "Retornos",
          route: "dashboard.returns"
        },
        {
          text: "Criar",
          active: true
        }
      ]
    };
  },
  mounted() {
    if (this.id) {
      this.formType = "edit";
      this.navigate_items[1].text = "Editar";
      this.getDataObject(this.id);
    }
  },
  methods: {
    save: function() {
      this.addFile();
      let errors = validator.validate(
        this.shipment_return,
        return_validate.model
      );
      if (errors.length == 0) {
        let data = JSON.parse(JSON.stringify(this.shipment_return));
        data.file = this.shipment_return.file;
        helper
          .saveFormDataObject(
            "shipments-return",
            data,
            this.formType,
            "Retorno",
            "a"
          )
          .then(() => {
            this.$router.push({ name: "dashboard.returns" });
          });
      } else {
        helper.showErrorsForm(errors);
      }
    },
    addFile: function() {
      this.shipment_return.file.push(this.newFile.file);
      this.newFile = {};
      this.$refs.newfileinput.reset();
    },
    uploadFile: function(e) {
      const file = e.target.files[0];
      if (e.target.files[0].size / 1000000 > 50) {
        this.$refs.newfileinput.reset();
        Swal.fire({
          title: "O tamanho máximo permitido é 50MB.",
          icon: "warning"
        });
        return;
      } else {
        this.newFile.file = file;
        this.refreshCondition = !this.refreshCondition;
      }
    }
  }
};
</script>
<style lang="scss">
@import "@/styles/general.scss";
</style>